<template>
  <main style="padding-top: 120px">
    <div class="container">
      <div class="delete-account">
        <img class="delete-icon" src="@/assets/img/icons/delete.svg" alt="Delete account" />

        <div class="delete-account-content">
          <h4>{{ $t('deleteAccount.title') }}</h4>
          <p>{{ $t('deleteAccount.subtitle') }}</p>
        </div>

        <div class="delete-account-action">
          <a href="tel:+37410300003">{{ $t('deleteAccount.link') }}</a>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {}
</script>

<style>
.delete-account {
  margin-inline: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 16px;
}

.delete-icon {
  display: block;
  max-width: 220px;
  margin-inline: auto;
}

.delete-account-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 12px;
  font-size: 16px;
}

.delete-account-content h4 {
  font-weight: bold;
}

.delete-account-action {
  margin-top: 40px;
}
.delete-account-action a {
  display: block;
  font-weight: bold;
  font-size: 16px;
  text-decoration: underline;
}

@media screen and (min-width: 320px) and (max-width: 990px) {
  .delete-account-content {
    font-size: 14px;
  }

  .delete-account-action {
    font-size: 14px;
  }
}
</style>
